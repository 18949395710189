import React, { useCallback, useEffect, useMemo, useState } from 'react';
import constants from 'appConstants';
import { useDispatch, useSelector } from 'react-redux';
import Grid from 'components/reusables/Grid';
import dayjs from 'dayjs';
import { loading, arrayOfObjectsShallowEqual, useToggle } from '@beewise/react-utils';
import DatePicker from 'react-datepicker';
import { getLastMessages } from './selectors';
import { FETCH_LAST_MESSAGES_BY_DATE_AND_COMMAND } from './actionTypes';
import { columnDefs, getDataToDisplay } from './utils';
import { fetchMessagesSinceLastSuccessByBhomeIdAndCommand, fetchLastMessagesByDateAndCommand } from './actions';
import { fetchBhomeConnectivityMap } from '../Monitoring/actions';
import BhUpdateHistoryModal from './components/BhUpdateHistoryModal/BhUpdateHistoryModal';

const SwUpdateMonitor = () => {
    const [dateRange, setDateRange] = useState([
        dayjs(new Date()).startOf('day').valueOf(),
        dayjs(new Date()).endOf('day').valueOf(),
    ]);
    const [startDate, endDate] = dateRange;
    const [isOpen, toggleModal] = useToggle();

    const messages = useSelector(getLastMessages, arrayOfObjectsShallowEqual);
    const connectivity = useSelector(state => state.monitoring.connectivity, arrayOfObjectsShallowEqual);
    const dispatch = useDispatch();

    const handleUpdate = useCallback(() => {
        if (startDate && endDate) {
            dispatch(
                fetchLastMessagesByDateAndCommand(startDate / 1000, endDate / 1000, constants.COMMANDS.UPDATE_CONFIG)
            );
        }
    }, [dispatch, endDate, startDate]);

    useEffect(() => {
        handleUpdate();
        dispatch(fetchBhomeConnectivityMap());
    }, [dispatch, handleUpdate]);

    const handleDateRangeChange = useCallback(range => {
        setDateRange([dayjs(range[0]).valueOf(), !dayjs(range[1]) ? null : dayjs(range[1]).endOf('day').valueOf()]);
    }, []);

    const dataToDisplay = useMemo(
        () =>
            getDataToDisplay({
                messages,
                connectivity,
            }),
        [connectivity, messages]
    );

    const handleOnClose = useCallback(() => {
        toggleModal();
    }, [toggleModal]);

    const onCellClicked = useCallback(
        params => {
            if (params.colDef.field !== 'bhome_id') {
                return;
            }
            dispatch(
                fetchMessagesSinceLastSuccessByBhomeIdAndCommand(
                    params.data.bhome_id,
                    constants.COMMANDS.UPDATE_CONFIG,
                    () => toggleModal()
                )
            );
        },
        [dispatch, toggleModal]
    );

    return (
        <div className="sw-update-monitor">
            <div className="sw-update-monitor-date-wrapper">
                <div>Filter by dates:</div>
                <DatePicker
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateRangeChange}
                    withPortal
                    maxDate={new Date()}
                />
            </div>
            <Grid
                rowData={dataToDisplay}
                columnDefs={columnDefs}
                hideDownload
                hideSearch
                onCellClicked={onCellClicked}
            />
            <BhUpdateHistoryModal isOpen={isOpen} handleOnClose={handleOnClose} />
        </div>
    );
};

export default loading([FETCH_LAST_MESSAGES_BY_DATE_AND_COMMAND.default])(SwUpdateMonitor);
