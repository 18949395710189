import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import { untuckOptions } from 'components/views/ActionsAndMonitoring/utils';

const MakeSpaceParams = ({ params, setParams }) => {
    const handleUntuckChange = useCallback(
        value => {
            setParams(params => ({
                ...params,
                direction: value,
            }));
        },
        [setParams]
    );

    return (
        <div className="close-hive-params">
            <SelectField
                value={params?.direction ?? null}
                options={untuckOptions}
                onChange={handleUntuckChange}
                placeholder="Untuck Direction"
                className="nowrap frame-type-select"
                size="small"
            />
        </div>
    );
};

MakeSpaceParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default MakeSpaceParams;
