import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Chip = ({ title, handleDeleteView, isActiveView, setActiveView }) => (
    <div
        className={cx('chip', {
            active: isActiveView,
        })}
    >
        <span className="checkmark">✓</span>
        <button className="title-button" onClick={setActiveView}>
            {title}
        </button>
        <button onClick={handleDeleteView} className="delete-button">
            X
        </button>
    </div>
);

Chip.propTypes = {
    title: PropTypes.string,
    handleDeleteView: PropTypes.func,
    isActiveView: PropTypes.bool,
    setActiveView: PropTypes.func,
};

export default Chip;
