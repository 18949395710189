import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isBoolean, isString } from 'lodash-es';
import cx from 'classnames';
import { getCurrentBhome, getCurrentBhomeActivities } from 'selectors';
import constants from 'appConstants';
import { fetchLatestBhomesAlert, clearLatestBhomeAlert } from 'components/views/Errors/actions';

const AppAlert = () => {
    const dispatch = useDispatch();
    const activities = useSelector(getCurrentBhomeActivities, shallowEqual);
    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const latestBhomeAlert = useSelector(state => state.errors.latestBhomeAlert, shallowEqual);

    useEffect(() => {
        if (bhome?.id && bhome?.status === constants.BHOME_STATUSES.ERROR) {
            dispatch(clearLatestBhomeAlert());
            setTimeout(() => {
                dispatch(fetchLatestBhomesAlert(bhome?.id));
            }, 15000);
        }
    }, [bhome?.id, bhome?.status, dispatch]);

    const isCenteringInProgress = useMemo(
        () =>
            activities?.active?.some(
                item =>
                    item.command === constants.COMMANDS.CENTERING_TEST &&
                    item.status === constants.MESSAGE_STATUS.PROCESSING
            ),
        [activities]
    );

    const isQridInProgress = useMemo(
        () =>
            activities?.active?.some(
                item =>
                    item.command === constants.COMMANDS.READ_QRID && item.status === constants.MESSAGE_STATUS.PROCESSING
            ),
        [activities]
    );

    const activeInterlocks = useMemo(() => {
        if (!bhome?.interlocks) {
            return null;
        }
        // falsy value means door (emergency) is open (on)
        return Object.keys(bhome?.interlocks).filter(key => {
            if (key === 'emergency_button') {
                return bhome.interlocks[key]?.toLowerCase() === 'pressed';
            } else if (key === 'doors') {
                return bhome.interlocks[key]?.toLowerCase() === 'open';
            }
            return !bhome.interlocks[key];
        });
    }, [bhome?.interlocks]);

    const activeMode = useMemo(() => {
        if (!bhome?.mode) {
            return false;
        }
        return Object.entries(bhome?.mode).find(([, value]) => value === true);
    }, [bhome?.mode]);

    const isMissingSlotMap = useMemo(() => {
        if (!bhome) {
            return false;
        }
        return isBoolean(bhome?.has_slot_map) ? !bhome?.has_slot_map : false;
    }, [bhome]);

    const showLatestBhomeAlert = latestBhomeAlert?.error && bhome?.status === constants.BHOME_STATUSES.ERROR;

    if (
        !isCenteringInProgress &&
        !activeInterlocks?.length &&
        !isQridInProgress &&
        !isMissingSlotMap &&
        !showLatestBhomeAlert &&
        !activeMode
    ) {
        return null;
    }

    return (
        <div className={cx('global-app-alert', { 'global-app-alert-bottom': showLatestBhomeAlert || activeMode })}>
            {isCenteringInProgress && <span className="global-app-alert-item">Slot Map in progress</span>}
            {isQridInProgress && <span className="global-app-alert-item">QRID in progress</span>}
            {isMissingSlotMap && <span className="global-app-alert-item">Missing beehome slot map file</span>}
            {!!activeInterlocks?.length && <span className="global-app-alert-item">{activeInterlocks.join(', ')}</span>}
            {showLatestBhomeAlert && (
                <span className="global-app-alert-item">
                    Error:{' '}
                    {isString(latestBhomeAlert?.error)
                        ? latestBhomeAlert?.error
                        : JSON.stringify(latestBhomeAlert?.error)}
                </span>
            )}
            {activeMode && <span className="global-app-alert-item">{activeMode} mode on</span>}
        </div>
    );
};

export default AppAlert;
