import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import Select, { components } from 'react-select';
import { SelectProps } from './props';
import Error from './Error';

import './SelectField.scss';

const DropdownIndicator = ({ ...props }) => (
  <components.DropdownIndicator {...props}>
    <div className="select-field__indicators" />
  </components.DropdownIndicator>
);

const Menu = (props) => {
  const { label, onClick } = props.selectProps.addNewOption;

  return (
    <components.Menu {...props} className="menu-list--creatable">
      {props.children}
      <button className="select-field-add" onClick={onClick}>
        {label}
      </button>
    </components.Menu>
  );
};

const largeHeight = 54;
const smallHeight = 40;

const SelectField = React.forwardRef(
  (
    {
      error,
      name,
      label,
      required,
      value,
      onFocus,
      onBlur,
      size = 'normal',
      type = 'text',
      components = {},
      className,
      onChange,
      info,
      addNewOption,
      disabled,
      // TODO remove once this dependency is removed in all apps
      placeholder,
      ...rest
    },
    ref
  ) => {
    const transformLabel = { '--height-transform': size === 'normal' ? largeHeight : smallHeight };

    const [isActive, setIsActive] = useState(!!value);

    const handleOnFocus = (e) => {
      setIsActive(true);
      if (onFocus) {
        onFocus(e);
      }
    };

    const handleOnBlur = (e) => {
      if (!value) {
        setIsActive(false);
      }
      if (onBlur) {
        onBlur(e);
      }
    };

    const handleOnChange = (e) =>
      Array.isArray(e) ? onChange(e.map((item) => item.value)) : onChange(e.value);

    const valueWithLabel = useMemo(
      () =>
        Array.isArray(value)
          ? rest.options.filter((option) => value.includes(option.value))
          : rest.options.find((option) => option.value === value),
      [value, rest.options]
    );

    const componentExtendedProps = addNewOption ? { Menu } : {};

    return (
      <fieldset className={cn('select-field-root', className)}>
        <Select
          {...rest}
          ref={ref}
          value={valueWithLabel || ''}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          className={cn(`select-field select-field--${size}`, { 'select-field--error': error })}
          classNamePrefix="select-field"
          placeholder={label ? '' : placeholder}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator,
            ...componentExtendedProps,
            ...components
          }}
          onChange={handleOnChange}
          addNewOption={addNewOption}
          isDisabled={disabled}
        />
        {label && (
          <label
            htmlFor={name}
            className={cn('select-field-label', {
              'select-field--active': isActive || value,
              'select-field--error': error
            })}
            style={transformLabel}
          >
            <span className="select-field-label--item">
              {label}
              {required && ' *'}
            </span>
          </label>
        )}
        <Error info={info} label={label} />
      </fieldset>
    );
  }
);

SelectField.propTypes = SelectProps;

export default SelectField;
