import { isEmpty, sortBy } from 'lodash-es';
import { FETCH_SIGNIN, SIGN_OUT, SOCKET_CONNECTION_FAILED } from 'components/views/SignIn/actionTypes';
import constants from 'appConstants';
import { auth, PERMISSIONS_ACTION_TYPES } from '@beewise/react-utils';
import {
    FETCH_ALL_BHOMES,
    SET_CURRENT_BHOME,
    ON_UPDATE_BHOME_STATUS,
    FETCH_TECHNICIAN_ACTIVITIES,
    ON_RECEIVE_NEW_MESSAGE,
    ON_UPDATE_MESSAGE_STATUS,
    ON_UPDATE_CONNECTED_USERS_LIST,
    FETCH_CURRENT_BHOME,
    ON_REPORT_HOURLY_SOLAR_DATA,
    ON_UPDATE_HONEY_LEVEL,
    ON_UPDATE_SYRUP_LEVEL,
    ON_UPDATE_INTERLOCK_STATUS,
    ON_GET_SYS_INFO,
    ON_UPDATE_BHOME_MAP,
    ON_UPDATE_GPS_LOCATION,
    FETCH_ALL_VIEWS,
    FETCH_CREATE_VIEW,
    FETCH_DELETE_VIEW,
} from 'actionTypes';
import { FETCH_UPDATE_LIVE_STREAM } from 'components/views/ActionsAndMonitoring/actionTypes';
import { FETCH_UPDATE_BHOME_NOTE } from 'components/views/Monitoring/actionTypes';
import { GET_CURRENT_BHOME_RECEPTION } from '../components/reusables/SideMenu/actionTypes';

auth.init({
    USER: 'beewise_technician_user',
    JWT_TOKEN: 'beewise_technician_jwt_token',
    SOCKET_JWT_TOKEN: 'beewise_technician_socket_jwt_token',
});

const parsedUser = auth.getUser();

const initialState = {
    user: parsedUser || {},
    isLoggedIn: !isEmpty(parsedUser),
    areBhomesFetched: false,
    bhomes: [],
    currentBhome: null,
    isCurrentBhomeOnline: false,
    currentBhomeActivities: {},
    currentBhomeUsers: [],
    permission: {},
    views: [],
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SIGNIN.success:
            const { token, socketToken } = action.data;
            auth.setJwtToken(token);
            auth.setSocketJwtToken(socketToken);

            return {
                ...state,
                user: auth.getUser(),
                isLoggedIn: true,
            };
        case FETCH_ALL_BHOMES.success:
            return {
                ...state,
                bhomes: sortBy(action.data.bhomes, 'id'),
                areBhomesFetched: true,
                currentBhome:
                    state.bhomes?.length && state.currentBhome && action.data?.bhomes?.length
                        ? action.data?.bhomes?.find(item => item.id === state.currentBhome.id)
                        : null,
                currentBhomeActivities: {},
                isCurrentBhomeOnline: false,
            };
        case FETCH_ALL_VIEWS.success:
            return {
                ...state,
                views: action.data.result,
            };
        case FETCH_CREATE_VIEW.success:
            return {
                ...state,
                views: [...state.views, action.data.result],
            };
        case FETCH_DELETE_VIEW.success:
            return {
                ...state,
                views: state.views.filter(view => view._id !== action.data.result._id),
            };
        case FETCH_UPDATE_BHOME_NOTE.success:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === action.data.bhome.id) {
                        return {
                            ...bhome,
                            note: action.data.bhome.note,
                            note_updated_at: action.data.bhome.note_updated_at,
                        };
                    }
                    return bhome;
                }),
                currentBhome:
                    state.currentBhome.id === action.data.bhome.id
                        ? {
                              ...state.currentBhome,
                              note: action.data.bhome.note,
                              note_updated_at: action.data.bhome.note_updated_at,
                          }
                        : state.currentBhome,
            };
        case FETCH_UPDATE_LIVE_STREAM.success:
            return {
                ...state,
                currentBhome: {
                    ...state.currentBhome,
                    live_stream: action.data.live_stream,
                },
            };
        case FETCH_TECHNICIAN_ACTIVITIES.success:
            return {
                ...state,
                currentBhomeActivities: action.data.messages,
            };
        case ON_UPDATE_CONNECTED_USERS_LIST:
            return {
                ...state,
                currentBhomeUsers: action.payload.users,
            };
        case FETCH_CURRENT_BHOME.success:
            if (action?.data?.bhome?.status) {
                return {
                    ...state,
                    bhomes: state.bhomes.map(bhome => {
                        if (bhome.id === action.data.bhome.id) {
                            return {
                                ...bhome,
                                ...action?.data?.bhome,
                            };
                        }
                        return bhome;
                    }),
                    currentBhome: {
                        ...state.currentBhome,
                        ...action?.data?.bhome,
                    },
                };
            }
            return state;
        case GET_CURRENT_BHOME_RECEPTION.success:
            return {
                ...state,
                isCurrentBhomeOnline: action.data.isOnline,
            };
        case SET_CURRENT_BHOME: {
            return {
                ...state,
                currentBhome: state.bhomes.find(bhome => bhome.id === action.bhomeId),
                currentBhomeActivities: {},
                currentBhomeUsers: [],
                isCurrentBhomeOnline: false,
            };
        }
        case ON_UPDATE_BHOME_STATUS:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === action.updatedBhome.id) {
                        return {
                            ...bhome,
                            status: action.updatedBhome.status,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome.id === action.updatedBhome.id
                        ? {
                              ...state.currentBhome,
                              status: action.updatedBhome.status,
                          }
                        : state.currentBhome,
            };
        case ON_UPDATE_GPS_LOCATION:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === Number(action.bhomeId)) {
                        return {
                            ...bhome,
                            gps: action.gps,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome.id === Number(action.bhomeId)
                        ? {
                              ...state.currentBhome,
                              gps: action.gps,
                          }
                        : state.currentBhome,
            };
        case ON_UPDATE_BHOME_MAP:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === action.updatedBhome.id) {
                        return {
                            ...bhome,
                            data: action.updatedBhome.data,
                            has_slot_map: action.updatedBhome.has_slot_map,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome.id === action.updatedBhome.id
                        ? {
                              ...state.currentBhome,
                              data: action.updatedBhome.data,
                              has_slot_map: action.updatedBhome.has_slot_map,
                          }
                        : state.currentBhome,
            };
        case ON_UPDATE_INTERLOCK_STATUS:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === Number(action.payload.bhomeId)) {
                        return {
                            ...bhome,
                            interlocks: action.payload.interlocks,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome.id === Number(action.payload.bhomeId)
                        ? {
                              ...state.currentBhome,
                              interlocks: action.payload.interlocks,
                          }
                        : state.currentBhome,
            };
        case ON_REPORT_HOURLY_SOLAR_DATA:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === Number(action.payload.bhomeId)) {
                        return {
                            ...bhome,
                            solar_data: action.payload.solar_data,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome.id === Number(action.payload.bhomeId)
                        ? {
                              ...state.currentBhome,
                              solar_data: action.payload.solar_data,
                          }
                        : state.currentBhome,
            };
        case ON_RECEIVE_NEW_MESSAGE:
            return {
                ...state,
                currentBhomeActivities: {
                    ...state.currentBhomeActivities,
                    active: [action.payload.messageResult, ...state.currentBhomeActivities.active],
                },
            };
        case ON_UPDATE_MESSAGE_STATUS:
            if (
                action.payload.status === constants.MESSAGE_STATUS.SUCCESS ||
                action.payload.status === constants.MESSAGE_STATUS.FAILED
            ) {
                return {
                    ...state,
                    currentBhomeActivities: {
                        active: state.currentBhomeActivities.active.filter(
                            activity => activity.id !== action.payload.messageId
                        ),
                        history: [
                            {
                                ...state.currentBhomeActivities.active.find(
                                    activity => activity.id === action.payload.messageId
                                ),
                                status: action.payload.status,
                            },
                            ...state.currentBhomeActivities.history,
                        ],
                    },
                };
            } else {
                return {
                    ...state,
                    currentBhomeActivities: {
                        ...state.currentBhomeActivities,
                        active: state.currentBhomeActivities.active.map(activity =>
                            activity.id === action.payload.messageId
                                ? {
                                      ...activity,
                                      status: action.payload.status,
                                  }
                                : activity
                        ),
                    },
                };
            }
        case SIGN_OUT:
        case SOCKET_CONNECTION_FAILED: {
            auth.clearUser();
            return {
                ...state,
                user: {},
                isLoggedIn: false,
                currentBhome: null,
                currentBhomeActivities: {},
                isCurrentBhomeOnline: false,
            };
        }
        case ON_UPDATE_SYRUP_LEVEL:
        case ON_UPDATE_HONEY_LEVEL:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === action.updatedBhome.id) {
                        return {
                            ...bhome,
                            sensors: action.updatedBhome.sensors,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome && state.currentBhome.id === Number(action.updatedBhome.id)
                        ? {
                              ...state.currentBhome,
                              sensors: action.updatedBhome.sensors,
                          }
                        : state.currentBhome,
            };
        case ON_GET_SYS_INFO:
            return {
                ...state,
                bhomes: state.bhomes.map(bhome => {
                    if (bhome.id === Number(action?.payload?.bhomeId)) {
                        return {
                            ...bhome,
                            sys_info: action?.payload?.data,
                        };
                    }

                    return bhome;
                }),
                currentBhome:
                    state.currentBhome && state.currentBhome.id === Number(action?.payload?.bhomeId)
                        ? { ...state.currentBhome, sys_info: action?.payload?.data }
                        : state.currentBhome,
            };
        case PERMISSIONS_ACTION_TYPES.FETCH_PERMISSION.success: {
            return {
                ...state,
                permission: action.data.permission,
                isPermissionFetched: true,
            };
        }
        default:
            return state;
    }
};

export default app;
