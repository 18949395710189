import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import constants from 'appConstants';
import useSuperAdmin from 'utils/hooks/useSuperAdmin';
import { UI_ENV } from 'config';
import { fetchCreatePlaceholderLayout, fetchGetAutoScan } from 'components/views/ActionsAndMonitoring/actions';

const FramesActions = ({ currentBhome }) => {
    const dispatch = useDispatch();
    const isSuperAdmin = useSuperAdmin();

    const handlePlaceholderLayoutCreation = useCallback(
        (isTwoFamilies = false) =>
            () => {
                if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                        `Are you sure you want to create placeholder layout${
                            isTwoFamilies ? ' (2 families per hive)' : ''
                        }?`
                    )
                ) {
                    dispatch(fetchCreatePlaceholderLayout(currentBhome.id, isTwoFamilies));
                }
            },
        [dispatch, currentBhome?.id]
    );

    const handleGetAutoScan = useCallback(
        status => () => {
            dispatch(fetchGetAutoScan(currentBhome.id, status));
        },
        [dispatch, currentBhome?.id]
    );

    return (
        <div className="frames-actions">
            {isSuperAdmin && (
                <div className="frames-actions-line">
                    <Button className="btn-primary" onClick={handlePlaceholderLayoutCreation(false)}>
                        Create placeholder layout
                    </Button>
                    <Button className="btn-primary" onClick={handlePlaceholderLayoutCreation(true)}>
                        Create 2 families per hive layout
                    </Button>
                </div>
            )}
            {isSuperAdmin && (UI_ENV === 'dev' || UI_ENV === 'lab') && (
                <div className="frames-actions-line">
                    <Button
                        className="btn-primary btn-success"
                        onClick={handleGetAutoScan(constants.MESSAGE_STATUS.SUCCESS)}
                    >
                        Get Success Autoscan
                    </Button>
                    <Button
                        className="btn-primary btn-error"
                        onClick={handleGetAutoScan(constants.MESSAGE_STATUS.FAILED)}
                    >
                        Get Failed Autoscan
                    </Button>
                </div>
            )}
        </div>
    );
};

FramesActions.propTypes = {
    currentBhome: PropTypes.shape(),
};

export default FramesActions;
