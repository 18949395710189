import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './Card.scss';

const Card = ({ title, className, children }) => (
    <div className={cx('card', { [className]: !!className })}>
        {title && <div className="card-header">{title}</div>}
        <div className="card-content">{children}</div>
    </div>
);

Card.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Card;
