import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import TextField from '@beewise/text-field';
import constants from 'appConstants';
import { frameStationOptions, frameTypeOptions } from 'components/views/ActionsAndMonitoring/utils';
import BhomeLayout from 'components/reusables/BhomeLayout';

const StationParams = ({
    action,
    onlyPartitionType,
    nonPartitionType,
    params,
    setParams,
    wrapperName,
    showX = true,
}) => {
    const [showYState, setShowYState] = useState(true);
    const [showZState, setShowZState] = useState(true);
    const [showIdState, setShowIdState] = useState(true);
    const [showBhomeLayoutState, setShowBhomeLayoutState] = useState(true);

    const handleActionChange = useCallback(() => {
        if (
            action === constants.COMMANDS.PUT_DOWN_FRAME ||
            action === constants.COMMANDS.ACQUIRE_IMAGE ||
            action === constants.COMMANDS.PULLOUT_FRAME
        ) {
            setShowYState(false);
            setShowZState(false);
            setShowBhomeLayoutState(false);
            setShowIdState(true);
        } else if (action === constants.COMMANDS.MAKE_SPACE) {
            setShowYState(false);
            setShowZState(false);
            setShowBhomeLayoutState(false);
            setShowIdState(false);
        } else if (
            action === constants.COMMANDS.ADD_SYRUP_TO_FEEDER ||
            action === constants.COMMANDS.FRAME_SCALE_CALIBRATE_FACTOR
        ) {
            setShowYState(false);
            setShowZState(false);
            setShowBhomeLayoutState(true);
            setShowIdState(true);
        } else {
            setShowYState(true);
            setShowZState(true);
            setShowBhomeLayoutState(true);
            setShowIdState(true);
        }
    }, [action]);

    useEffect(() => {
        handleActionChange();
    }, [action, handleActionChange]);

    const handleFrameTypeChange = useCallback(
        (e, value) => {
            if (wrapperName) {
                setParams(params => ({
                    ...params,
                    [wrapperName]: {
                        ...(params?.[wrapperName] ?? {}),
                        frame: {
                            ...(params?.[wrapperName]?.frame ?? {}),
                            frame_type: e || value.value,
                        },
                    },
                }));
            } else if (action === constants.COMMANDS.MAKE_SPACE) {
                setParams(params => ({
                    ...params,
                    frame_type: e || value.value,
                }));
            } else {
                setParams(params => ({
                    ...params,
                    frame: {
                        ...(params.frame ?? {}),
                        frame_type: e || value.value,
                    },
                }));
            }
        },
        [action, setParams, wrapperName]
    );

    const handleFrameIdChange = useCallback(
        e => {
            const currentValue = typeof e === 'object' && e.target ? e.target.value : e;
            if (wrapperName) {
                setParams(params => ({
                    ...params,
                    [wrapperName]: {
                        ...(params?.[wrapperName] ?? {}),
                        frame: {
                            ...(params?.[wrapperName]?.frame ?? {}),
                            id: currentValue,
                        },
                    },
                }));
            } else if (action === constants.COMMANDS.MAKE_SPACE) {
                setParams(params => ({
                    ...params,
                    place: {
                        ...(params?.place ?? {}),
                        id: currentValue,
                    },
                }));
            } else {
                setParams(params => ({
                    ...params,
                    frame: {
                        ...(params.frame ?? {}),
                        id: currentValue,
                    },
                }));
            }
        },
        [action, setParams, wrapperName]
    );

    const handleStationChange = useCallback(
        (e, value) => {
            if (wrapperName) {
                setParams(params => ({
                    ...params,
                    [wrapperName]: {
                        ...(params?.[wrapperName] ?? {}),
                        frame: {
                            ...(params?.[wrapperName]?.frame ?? {}),
                            place: {
                                ...(params?.[wrapperName]?.frame?.place ?? {}),
                                station: e || value.value,
                            },
                        },
                    },
                }));
            } else if (action === constants.COMMANDS.MAKE_SPACE) {
                setParams(params => ({
                    ...params,
                    place: {
                        ...(params?.place ?? {}),
                        station: e || value.value,
                    },
                }));
            } else {
                setParams(params => ({
                    ...params,
                    frame: {
                        ...(params.frame ?? {}),
                        place: {
                            ...(params?.frame?.place ?? {}),
                            station: e || value.value,
                        },
                    },
                }));
            }
        },
        [action, setParams, wrapperName]
    );

    const handleChangePosition = useCallback(
        axis => e => {
            const currentValue = typeof e === 'object' && e.target ? e.target.value : e;
            if (wrapperName) {
                setParams(params => ({
                    ...params,
                    [wrapperName]: {
                        ...(params?.[wrapperName] ?? {}),
                        frame: {
                            ...(params?.[wrapperName]?.frame ?? {}),
                            place: {
                                ...(params?.[wrapperName]?.frame?.place ?? {}),
                                position: {
                                    ...(params?.[wrapperName]?.frame?.place?.position ?? {}),
                                    [axis]: currentValue?.endsWith('.') ? currentValue : Number(currentValue),
                                },
                            },
                        },
                    },
                }));
            } else if (action === constants.COMMANDS.MAKE_SPACE) {
                setParams(params => ({
                    ...params,
                    place: {
                        ...(params?.place ?? {}),
                        position: {
                            [axis]: currentValue?.endsWith('.') ? currentValue : Number(currentValue),
                        },
                    },
                }));
            } else {
                setParams(params => ({
                    ...params,
                    frame: {
                        ...(params.frame ?? {}),
                        place: {
                            ...(params?.frame?.place ?? {}),
                            position: {
                                ...(params?.frame?.place?.position ?? {}),
                                [axis]: currentValue?.endsWith('.') ? currentValue : Number(currentValue),
                            },
                        },
                    },
                }));
            }
        },
        [action, setParams, wrapperName]
    );

    const typeOptions = useMemo(
        () =>
            frameTypeOptions({
                onlyPartition: action === constants.COMMANDS.OPEN_HIVE || onlyPartitionType,
                nonPartitionType,
                onlyFeeder: action === constants.COMMANDS.FILL_SYRUP,
                nonQueen: action === constants.COMMANDS.PUT_DOWN_FRAME || action === constants.COMMANDS.ACQUIRE_IMAGE,
                nonStaticPartition:
                    action === constants.COMMANDS.PULLOUT_FRAME || action === constants.COMMANDS.MAKE_SPACE,
            }),
        [action, nonPartitionType, onlyPartitionType]
    );

    const stationOptions = frameStationOptions({
        partialStationOptions:
            action === constants.COMMANDS.PUT_DOWN_FRAME || action === constants.COMMANDS.ACQUIRE_IMAGE,
    });

    const onFrameClick = useCallback(
        frame => {
            if (
                (action === constants.COMMANDS.FILL_SYRUP ||
                    action === constants.COMMANDS.ADD_SYRUP_TO_FEEDER ||
                    action === constants.COMMANDS.FRAME_SCALE_CALIBRATE_FACTOR) &&
                frame?.type !== constants.FRAME_TYPES.FEEDER
            ) {
                return;
            }
            if (action === constants.COMMANDS.CLOSE_HIVE && frame?.type !== constants.FRAME_TYPES.PARTITION) {
                return;
            }
            if (frame?.type) {
                handleFrameTypeChange(null, { value: frame.type });
            }
            if (frame?.place?.station) {
                handleStationChange(null, { value: frame?.place?.station });
            }
            if (frame?.rfid && showIdState) {
                handleFrameIdChange({ target: { value: frame.rfid } });
            }
            if (frame?.place?.position?.x && showX) {
                handleChangePosition('x')({
                    target: { value: `${frame?.place?.position?.x}` },
                });
            }
            if (frame?.place?.position?.y && showYState) {
                handleChangePosition('y')({
                    target: { value: `${frame?.place?.position?.y}` },
                });
            }
            if (frame?.place?.position?.z && showZState) {
                handleChangePosition('z')({
                    target: { value: `${frame?.place?.position?.z}` },
                });
            }
        },
        [
            action,
            handleChangePosition,
            handleFrameIdChange,
            handleFrameTypeChange,
            handleStationChange,
            showIdState,
            showX,
            showYState,
            showZState,
        ]
    );

    return (
        <>
            <SelectField
                value={
                    (wrapperName
                        ? params?.[wrapperName]?.frame?.frame_type
                        : params?.frame?.frame_type || params?.frame_type) ?? null
                }
                options={typeOptions}
                onChange={handleFrameTypeChange}
                placeholder="Select frame type"
                className="nowrap frame-type-select"
                size="small"
            />
            <SelectField
                value={
                    (wrapperName
                        ? params?.[wrapperName]?.frame?.place?.station
                        : params?.frame?.place?.station || params?.place?.station) ?? null
                }
                options={stationOptions}
                onChange={handleStationChange}
                placeholder="Select station"
                className="nowrap"
                size="small"
            />
            {showIdState && (
                <TextField
                    label="id"
                    value={
                        (wrapperName ? params?.[wrapperName]?.frame?.id : params?.frame?.id || params?.place?.id) ?? ''
                    }
                    onChange={handleFrameIdChange}
                    size="small"
                />
            )}
            {showX && (
                <TextField
                    label="x"
                    value={
                        (wrapperName
                            ? params?.[wrapperName]?.frame?.place?.position?.x
                            : params?.frame?.place?.position?.x || params?.place?.position?.x) ?? ''
                    }
                    onChange={handleChangePosition('x')}
                    size="small"
                />
            )}
            {showYState && (
                <TextField
                    label="y"
                    value={
                        (wrapperName
                            ? params?.[wrapperName]?.frame?.place?.position?.y
                            : params?.frame?.place?.position?.y) ?? ''
                    }
                    onChange={handleChangePosition('y')}
                    size="small"
                />
            )}
            {showZState && (
                <TextField
                    label="z"
                    value={
                        (wrapperName
                            ? params?.[wrapperName]?.frame?.place?.position?.z
                            : params?.frame?.place?.position?.z) ?? ''
                    }
                    onChange={handleChangePosition('z')}
                    size="small"
                />
            )}
            {showBhomeLayoutState && <BhomeLayout onFrameClick={onFrameClick} />}
        </>
    );
};

StationParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
    wrapperName: PropTypes.string,
    action: PropTypes.string,
    showX: PropTypes.bool,
    onlyPartitionType: PropTypes.bool,
    nonPartitionType: PropTypes.bool,
};

export default StationParams;
