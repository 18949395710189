import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNumber } from 'lodash-es';
import constants from 'appConstants';
import { loading } from '@beewise/react-utils';
import { getCurrentBhome } from 'selectors';
import analytics from 'utils/analytics';
import getAbsoluteSlotIndex from 'utils/getAbsoluteSlotIndex';
import { showToast } from '@beewise/toast';
import { HIVES } from 'utils';
import { fetchFrameByRfid, fetchUpdateFrame, fetchCreateFrame, fetchUpdateLayout, clearFrame } from './actions';
import { FETCH_FRAME_BY_RFID, FETCH_FRAME_UPDATE, FETCH_CREATE_FRAME, FETCH_LAYOUT_UPDATE } from './actionTypes';
import CreateFrame from './components/CreateFrame';
import EditFrame from './components/EditFrame';
import FramesActions from './components/FramesActions';
import { fetchFrames } from '../ActionsAndMonitoring/actions';

const SLOT_STATUS_OPTIONS = Object.keys(constants.SLOT_STATUS).map(key => ({
    label: constants.SLOT_STATUS[key],
    value: constants.SLOT_STATUS[key],
}));

const FRAME_OPTIONS = Object.keys(constants.FRAME_TYPES).reduce((acc, key) => {
    if (key !== 'FOUNDATION' && key !== 'EMPTY') {
        acc.push({
            label: constants.FRAME_TYPES[key],
            value: constants.FRAME_TYPES[key],
        });
    }
    return acc;
}, []);

const Frames = () => {
    const [error, setError] = useState(null);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const currentFrame = useSelector(state => state.frames.frame, shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentBhome?.id) {
            dispatch(fetchFrames(currentBhome.id));
        }
    }, [dispatch, currentBhome?.id]);

    const handleGetByRfidClick = useCallback(
        rfid => () => {
            setError(null);

            if (rfid) {
                dispatch(
                    fetchFrameByRfid(currentBhome.id, rfid, data => {
                        if (isEmpty(data)) {
                            setError('No frame with passed rfid was found.');
                        }
                    })
                );
            }
        },
        [dispatch, currentBhome]
    );

    const handleUpdateFrame = useCallback(
        ({ editRfid, status, type, sideA, sideB, prevSideA, prevSideB, cleanUpFrameId = false, isEditingRfid }) =>
            () => {
                if (editRfid && status) {
                    analytics.fireFrameEvent({
                        action: 'Update Frame',
                        label: `Beehome ${currentBhome.id}`,
                    });

                    try {
                        const sides =
                            currentFrame.type === constants.FRAME_TYPES.COMB_FRAME
                                ? {
                                      side_a: JSON.parse(sideA),
                                      side_b: JSON.parse(sideB),
                                      previous_side_a: JSON.parse(prevSideA),
                                      previous_side_b: JSON.parse(prevSideB),
                                  }
                                : {};

                        dispatch(
                            fetchUpdateFrame(currentBhome.id, currentFrame.id, isEditingRfid, {
                                status,
                                rfid: editRfid,
                                type,
                                ...sides,
                            })
                        );
                    } catch (e) {
                        showToast('Broken JSON in textarea', {
                            toastType: 'toast-error',
                        });
                    }
                } else if (status && currentFrame?.id) {
                    dispatch(
                        fetchUpdateLayout({
                            bhomeId: currentBhome.id,
                            frameId: currentFrame?.id,
                            updateBody: {
                                status,
                                ...(cleanUpFrameId ? { frameId: null } : {}),
                            },
                            resolver: () => {
                                if (cleanUpFrameId) {
                                    dispatch(clearFrame());
                                }
                            },
                        })
                    );
                }
            },
        [dispatch, currentFrame, currentBhome]
    );

    const handleFrameSave = useCallback(
        (fields, pickedHive, pickedSlot, positionX, resolver) => () => {
            const slotIndex = getAbsoluteSlotIndex(currentBhome, pickedHive, pickedSlot);
            if (!isEmpty(fields) && (isNumber(slotIndex) || positionX)) {
                dispatch(fetchCreateFrame(currentBhome.id, fields, slotIndex, positionX, pickedHive, resolver));
            }
        },
        [dispatch, currentBhome]
    );

    return (
        <div className="frames-view">
            <h2>Actions</h2>
            <FramesActions currentBhome={currentBhome} />
            <h2>Edit Frame:</h2>
            <EditFrame
                currentBhome={currentBhome}
                currentFrame={currentFrame}
                error={error}
                handleGetByRfidClick={handleGetByRfidClick}
                handleUpdateFrame={handleUpdateFrame}
                slotStatusOptions={SLOT_STATUS_OPTIONS}
                frameOptions={FRAME_OPTIONS}
            />
            <h2 className="section-header">Create Frame:</h2>
            <CreateFrame
                currentBhome={currentBhome}
                slotStatusOptions={SLOT_STATUS_OPTIONS}
                hives={HIVES}
                handleFrameSave={handleFrameSave}
                frameOptions={FRAME_OPTIONS}
            />
        </div>
    );
};

export default loading([
    FETCH_FRAME_BY_RFID.default,
    FETCH_CREATE_FRAME.default,
    FETCH_FRAME_UPDATE.default,
    FETCH_LAYOUT_UPDATE.default,
])(Frames);
